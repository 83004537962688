<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const svgFile = defineAsyncComponent(() =>
  import(`../../assets/images/${props.name}.svg`)
);
</script>

<template>
  <component :is="svgFile" />
</template>
